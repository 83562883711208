<template>
  <div>
    <activator
      @search="search"
      placeholder="ابحث عن سؤال"
      btnText="سؤال جديد"
      @ok="navigateToCreateQuestion"
    ></activator>
  </div>
</template>

<script>
import Activator from "@/EK-core/components/EK-dialog/activaitor.vue";
import { nullGuid } from "@Ekcore/util/global.js";

export default {
  components: {
    Activator,
  },
  data: () => ({
    nullGuid
  }),
  methods: {
    search(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ['question', 'answer', "date"],
        query,
      });
    },
    navigateToCreateQuestion() {
        this.$router.push("/admin/settings/questions/addQuestion")
    }
  },
};
</script>

<style>
</style>